import { useHead, useRuntimeConfig } from '#imports'
import { defineNuxtPlugin } from 'nuxt/app'
import { withQuery } from 'ufo'

export default defineNuxtPlugin({
  name: 'gtag',
  parallel: true,
  setup() {
    const gtagConfig = useRuntimeConfig().public.gtag

    if (!gtagConfig?.id) {
      console.warn('No Google Analytics ID provided')
      return
    }

    if (!gtagConfig?.url) {
      console.warn('No Google Analytics URL provided')
      return
    }

    useHead({
      script: [
        {
          async: true,
          src: withQuery(gtagConfig.url, { id: gtagConfig.id }),
          type: 'text/partytown',
        },
        {
          innerHTML: [
            `((w) => {`,
            `w.dataLayer = w.dataLayer || []`,
            `w.gtag=function(){w.dataLayer.push(arguments)}`,
            `w.gtag('js', new Date())`,
            `w.gtag('config', '${gtagConfig.id}')`,
            `})(window)`,
          ].join('\n'),
          type: 'text/partytown',
        },
      ],
    })
  },
})
