export default defineNuxtPlugin({
  name: 'console-message',
  parallel: true,
  setup() {
    const siteConfig = useSiteConfig()
    const primaryFillColor = '#fff'
    const primaryBackgroundColor = '#000'
    const accentColor = '#66c191'

    const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 500 500"><path fill="${primaryFillColor}" d="M182.4 31.2a12.5 12.5 0 0 0-21.7 0L43.8 233h-.7a26.1 26.1 0 1 0 22.4 12.6l106-183.2 106.1 183.2a26.1 26.1 0 1 0 21.6-12.5l-116.7-202Z"/><path fill="${primaryFillColor}" d="M470.8 353a12.5 12.5 0 0 0 10.9-18.8L365.4 132.1l.4-.6a26.1 26.1 0 1 0-22 13L449.1 328l-211.6.3a26.1 26.1 0 1 0 0 25l233.2-.3Z"/><path fill="${primaryFillColor}" d="M47 454.7a12.5 12.5 0 0 1 0-12.5l116.3-202.1-.3-.6a26.1 26.1 0 1 1 22 13L79.5 436l211.6.3a26.1 26.1 0 1 1 0 25.2v-.2L57.8 461c-4.5 0-8.6-2.3-10.8-6.2Z"/></svg>`
    const svgDataUrl = 'data:image/svg+xml;base64,' + btoa(svgLogo)

    const logoStyle = [
      'font-size: 2rem',
      'font-family: Urbanist, sans-serif',
      'font-weight: 500',
      `color: ${primaryFillColor}`,
      `background-image: url(${svgDataUrl})`,
      'background-size: auto 1em',
      'background-position: 0.5em center',
      'background-repeat: no-repeat',
      'padding: 0.5em 0.5em 0.5em 1.5em',
      `border: 2px solid ${primaryFillColor}`,
      'border-radius: 0.3em',
      `background-color: ${primaryBackgroundColor}`,
    ].join(';')

    const headerStyle = [
      'font-size: 1.5rem',
      'margin-bottom: 0.3em',
      `text-shadow: 0px 2px 2px hsla(0, 0%, 0%, 0.6)`,
    ].join(';')

    const bodyStyle = [ 'font-size: 1rem' ].join(';')
    const linkStyle = [
      'font-size: 1rem',
      `color: ${accentColor}`,
      `text-shadow: 0px 0.5px 0.5px hsla(0, 0%, 0%, 0.2)`,
    ].join(';')

    if (siteConfig.env === 'production' || siteConfig.env === 'stage') {
      console.log(
        [
          '\n%c Kaweah Tech%c\n',
          `Interested in being part of the team?`,
          `%cWe aren't currently hiring, but we hope to be soon.`,
          `Let us know your interest by email at %ccareers@kaweah.tech%c.`,
          `We'd love to hear about your journey and how you are looking to grow.`,
        ].join('\n'),
        logoStyle,
        headerStyle,
        bodyStyle,
        linkStyle,
        bodyStyle,
      )
    }
  },
})
