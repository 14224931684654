<script setup lang="ts">
const siteConfig = useSiteConfig()

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    { href: '/favicon.svg', rel: 'icon', type: 'image/svg+xml' },
    { href: '/apple-touch-icon.png', rel: 'apple-touch-icon' },
    { href: '/site.webmanifest', rel: 'manifest' },
  ],
  meta: [
    { content: 'width=device-width, initial-scale=1', name: 'viewport' },
    { content: siteConfig.version, name: 'version' },
    { content: siteConfig.env, name: 'environment' },
  ],
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
</template>
